import React from "react";
import { useEffect, useState } from "react";
import { ButtonList, GalleryLink, StyledSiteName } from "./PageElements";
import axios from "axios";


const GallerySelection = () => {
    const [folderList, setFolderList] = useState([]);

    useEffect(() => {
        getUserToken();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /** get a user token */
    function getUserToken() {
        axios.get(process.env.REACT_APP_SFTPGO_URL + '/api/v2/user/token', 
                {headers: {"Authorization": "Basic d2Vic2l0ZTo1d2JWdXo3NWpMRiU0Q2Y="}})
            .then(response => {
                // console.log(response.data.access_token)
                localStorage.setItem('usertoken', response.data.access_token)
                getAlbumFolders();
            })
            .catch (error => {
                console.error(error)
            })
    }

    /** get a user directory listing, specifically the base one which is the collection of albums
     * save the listing as the album list
     */
    function getAlbumFolders() {
        axios.get(process.env.REACT_APP_SFTPGO_URL + '/api/v2/user/shares', {headers: {"Authorization": "Bearer " + localStorage.getItem('usertoken')}})
            .then(response => {
                let folders = []
                response.data.forEach(folder => {
                    folders.push([folder.name, folder.id]);
                });
        
                // folders come in "chronological" order (alphabetic, starting with yearmonth)
                // but I want the newest at the top
                folders.sort();
                folders.reverse();
                setFolderList(folders);

                if (response.status === 401) {
                    getUserToken()
                }
            })
            .catch (error => {
                console.error(error)
            })
    }

    function formatAlbumName(folder) {
        return folder.split(/(?=[A-Z])/).join(" ");
    }

    return (
        <div>
            <StyledSiteName>Albums</StyledSiteName>
            <ButtonList>
                {folderList.map((folder) => (
                    <GalleryLink key={folder[1]} to={'/gallery'} state={folder[1]}>{formatAlbumName(folder[0])}</GalleryLink>   
                ))}  
            </ButtonList>            
        </div>
        );
}
 
export default GallerySelection;