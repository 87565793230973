import React from "react";
import { Nav, NavLink, NavMenu } from "./NavigationElements"; 

const Navigation = () => {
    return (
        <Nav>
            <NavMenu>
                {/* <NavLink to="/about">
                    About
                </NavLink> */}
                <NavLink to="/contact">
                    Contact
                </NavLink>
                <NavLink to="/galleries">
                    Gallery
                </NavLink>
                <NavLink to="/index">
                    Home
                </NavLink>
            </NavMenu>
        </Nav>
    );
};
 
export default Navigation;