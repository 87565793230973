import React from "react";
import { StyledParagraph } from "./PageElements";

// https://www.albionresearch.com/tools/obfuscator for non-plain text mailto
const Contact = () => {
    return (
        <div>
            <StyledParagraph>
                <b>Hello! If there's something you'd like to discuss with me, please send me an email!</b>
            </StyledParagraph>
            <StyledParagraph>
                <b>A note on photo permissions for my event photography:</b> I try to reach out to anyone I recognize from an event and 
                can get contact information for to ask about photo preferences and posting permissions. However events are 
                busy places, I am far from knowing everyone there, and I inevitably don't catch everyone. 
                If you see yourself in a photo and aren't aware of me, please reach out! 
                If we have talked but your preferences have changed or you see 
                a particular photo you'd like taken down, please also reach out to let me know!
            </StyledParagraph>
            <StyledParagraph>
                <b>A note on photo use:</b> I enjoy knowing when other people appreciate or want to use my photos!
                The images posted here are scaled down somewhat for speed and effeciency.
                If you've got an SCA newsletter or publication, and would like a larger version, please reach out and 
                let me know what photo(s) you are looking for. Since I've got thousands of event photos, 
                the more specific you can be, the faster I can likely find them!
            </StyledParagraph>
            <StyledParagraph>
            <a href='&#109;ailt&#111;&#58;%67re%74%61&#46;%&#54;7%7&#50;unwald&#46;%7&#51;&#37;6&#51;a&#64;%&#54;&#55;&#109;a&#105;&#37;6C&#46;&#99;om'>&#103;r&#101;&#116;a&#46;&#103;&#114;u&#110;wa&#108;d&#46;&#115;ca&#64;g&#109;&#97;il&#46;com</a>            </StyledParagraph>
        </div>
    );
};
 
export default Contact;