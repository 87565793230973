import React from "react";
import ImageGallery from "react-image-gallery"; 
import "react-image-gallery/styles/css/image-gallery.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";


const Gallery = () => {
    const [galleryImages, setGalleryImages] = useState([]);
    const { state } = useLocation();
    const folder = state;

    useEffect(() => {
        getImageList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function getImageList() {
        axios.get(process.env.REACT_APP_SFTPGO_URL + '/api/v2/shares/' + folder + '/dirs')
            .then(response => {
                let images = []
                response.data.forEach(image => {
                    if (!image.name.includes("thumbnail")) {
                        const imageDict = {
                            loading: "eager",
                            thumbnailLoading: "lazy",
                            original: process.env.REACT_APP_SFTPGO_URL + "/api/v2/shares/" + folder + "/files?path=%2F" + image.name.replace(" ", "%20"),
                            thumbnail: process.env.REACT_APP_SFTPGO_URL + "/api/v2/shares/" + folder + "/files?path=%2Fthumbnails%2F"+ image.name.replace(" ", "%20"),
                        }
                        images.push(imageDict);
                    }
                });

                // make sure the images are in chronological (name) order)
                images.sort((a, b) => {
                    if (a.original < b.original) {
                        return -1;
                      }
                      if (a.original > b.original) {
                        return 1;
                      }
                      // names must be equal, though this would mean duplicates which there shouldn't be
                      return 0;
                })
                setGalleryImages(images);
            })
            .catch (error => {
                console.error(error)
            })
    }

    return (
        <div>
            <ImageGallery items={galleryImages} lazyLoad={true} showNav={true} showThumbnails={true} showIndex={true}/>
        </div>
    );
}
 
export default Gallery;