import styled from "styled-components";
import { Link } from 'react-router-dom';
import { site_green } from '../StylingVariables';

export const MenuButton = styled.button`
    color: ${site_green};
    padding: 2%;
    border: none;
    background: #FFFFFF;
`;

export const StyledSkillsElement = styled.li`
    font-family: Lucida Sans Unicode;
    padding: 2%;
    width: 100%;
`

export const StyledSkillsList = styled.ul`
    list-style: none;
    float: left;
    text-align: left;
    padding: none;
    margin: none;
`;

export const Column = styled.div`
    float: left;
    width: 50%;
`;
  
export const RoundImage = styled.img`
    border-radius: 50%;
    float: right;
    padding: 0% 8%;
    width: 80%;
`;

export const HeaderImage = styled.img`
    padding: 0% 0% 5% 0%;
    width: 100%;
`;

export const StyledSiteName = styled.h1`
    font-family: "Brush Script MT", cursive;
`

export const StyledParagraph = styled.p`
    padding: 2% 15% 2% 15%;
`;

export const ButtonList = styled.div`
    display: flex; 
    flex-flow: wrap;
    justify-content: center;
    gap: 1%;
`

export const GalleryLink = styled(Link)`
    background: ${site_green};
    border-radius: 10px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px;
    margin: 10px;
`;
