import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { site_green } from '../../StylingVariables';

export const Nav = styled.nav`
    height: 85px;
    display: flex;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    align-items: center;
`;
 
export const NavLink = styled(Link)`
    color: ${site_green};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #000000;
    }
`;
 
export const NavMenu = styled.div`
    display: flex;
`;
