import React from "react";
import Navigation from "./components/navigation_bar/Navigation";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Index from "./pages";
// import About from "./pages/about";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";
import GallerySelection from "./pages/gallerySelection";
import './App.css';

function App() {
  return (
    <div className="App">
      {process.env.REACT_APP_MAINTENANCE_MODE === "true" ? <h1>Site under maintenance</h1> : 
        <Router>
          <Navigation/>
          <Routes>
              <Route exact path="/" element={<Index/>}/>
              <Route path="/index" element={<Index/>}/>
              {/* <Route path="/about" element={<About/>}/> */}
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/gallery" element={<Gallery/>}/>
              <Route path="/galleries" element={<GallerySelection/>}/>
          </Routes>
        </Router>
    }
    </div>
  );
}

export default App;
