import React from "react";
import { HeaderImage, StyledSiteName } from "./PageElements";
import daisyImage from "../images/2022_05_01_15_45_51_2H6A4496 2.jpg";


const Index = () => {
    return (
        <div>
            <HeaderImage src={daisyImage} alt="Daisy Header Image"></HeaderImage>
            <StyledSiteName>Welcome to Daisy Designs and Photography</StyledSiteName>
        </div>
    );
};
 
export default Index;